<template>
  <div id="rzp-modal" class="rzp-modal-container">
    <div class="rzp-center">
      <Loading v-if="loading" />

      <div v-else class="rzp-modal">
        <div class="rzp-close-container">
          <img
            class="rzp-close-icon"
            src="@/assets/icons/close_icon.png"
            @click="closeModal()"
          />
        </div>

        <div class="rzp-modal-body">
          <div class="rzp-row">
            <div class="rzp-plan-details">
              <OrderSummary
                v-if="orderDetail && orderDetail.detail"
                :couponCodeProp="couponCode"
                :orderDetails="orderDetail"
                @totalAfterDiscount="totalAfterDiscount"
              ></OrderSummary>
            </div>

            <div class="rzp-payment-details">
          
              <img src="@/assets/icons/razorpay_banner.svg"  class="razorpay-banner" alt="" v-if="paymentModes[selectedIndex].key !== 'paypal'" />
              <!-- <p class="rzp-bold-text rzp-fs-bold" v-if="paymentModes[selectedIndex].key !== 'paypal'">Subscribe Now</p> -->
              <!--
              <p class="rzp-bold-text rzp-fs-bold">{{ $t("Select Payment Mode") }}</p>

              -->
              <div v-for="(paymentMode, index) in paymentModes" :key="index">
                <SelectPaymentMethod
                  :data="paymentMode"
                  :index="index"
                  :icon="paymentMode.icon"
                  @onSelectItemChange="changeItem"
                  class="rzp-mt-default"
                />
              </div>

              <div class="rzp-mt-max">
              <paypalSub v-if="paymentModes[selectedIndex].key === 'paypal'" gatewaydetail="PAYPALSUB" :paymentDetail="orderDetail"></paypalSub>

                <button v-else @click="initiatePayment" class="button-primary">
                  {{ $t("PAY NOW") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { showSnackBar } from "@/utilities";
import { mapGetters } from "vuex";

export default {
  props: ["onOpen", "onClose", "plan"],
  data() {
    return {
      loading: false,
      couponCode: "",
      orderDetail: null,
      couponAmount: 0,
      paymentModes: [],
      selectedIndex: 0,
      referencedata: null,
      referenceid: null,
    };
  },
  created() {
    console.log("this.appConfig.planmappinggateway['razorpay']", this.appConfig.planmappinggateway['razorpay'])
    let razorpayplan = this.appConfig.planmappinggateway && this.appConfig.planmappinggateway['razorpay'] ? this.appConfig.planmappinggateway['razorpay'] : ["7JOLYPFO", "KINGAERA", "OVRIEZPO", "SXNPW0K6"];

    let paypalplan = this.appConfig.planmappinggateway && this.appConfig.planmappinggateway['paypal'] ? this.appConfig.planmappinggateway['paypal'] : ["WB942GUD"]

    this.paymentModes = [
        {
          title: "Credit Card/Debit Card",
          description: "Use your credit card or debit card to make the payment",
          icon: "credit_card.svg",
          active: true,
          key: "CC",
          alias: "card",
          gatewayid: "RAZORPAY",
          planid: razorpayplan
        },
        {
          title: "UPI",
          description: "Pay with your UPI ID",
          icon: "upi.svg",
          active: false,
          key: "UPI",
          alias: "upi",
          gatewayid: "RAZORPAY",
          planid: razorpayplan

        },
        {
          title: "Net Banking",
          description: "Use your net banking account to make the payment",
          icon: "net_banking.svg",
          active: false,
          key: "NB",
          alias: "netbanking",
          gatewayid: "RAZORPAY",
          planid: razorpayplan
        },
        {
          title: "Wallet",
          description: "Use your Wallet to make the payment",
          icon: "wallet.svg",
          active: false,
          key: "WA",
          alias: "wallet",
          gatewayid: "RAZORPAY",
          planid: razorpayplan
        },
        {
          title: "Paypal",
          description: "Use your PayPal account to make the payment",
          icon: "paypal.png",
          active: true,
          key: "paypal",
          alias: "paypal",
          gatewayid: "PAYPALSUB",
          planid: paypalplan
        },
      ];


    eventBus.$on("initiate-razorpay-init-response", (data) => {
      this.initiateSdk(data);
    });
    eventBus.$on("razorpay-callback-response", (data) => {
        
        setTimeout(() => {
          this.closeModal();
          // this.$router.go()
          this.loading = false;

          showSnackBar(this.$t("Payment Successful"),this.$t('DISMISS'));


          eventBus.$emit("successfullpayment-event", this.orderDetail.detail);

        }, this.appConfig.razorpaySuccessCounter || 20000);

    });
    eventBus.$on("free-subscription-response", (response) => {
      if (!response.reason) {
        showSnackBar(this.$t("Payment Successful"),this.$t('DISMISS'));
        eventBus.$emit("successfullpayment-event", response);
        this.closeModal();
      } else {
        showSnackBar(response.reason, this.$t('DISMISS'));
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("initiate-razorpay-init-response");
    eventBus.$off("razorpay-callback-response");
  },
  methods: {
    totalAfterDiscount (d) {
      console.log(d)
      // this.couponAmount = d.totalFinalCost;
      this.orderDetail.couponPrice = d.totalFinalCost;
      this.orderDetail.couponCode = d.couponCode;
    },
    changeItem(index) {
      this.selectedIndex = index;
      let data = this.paymentModes;
      let updatedArr = [];
      for (var i = 0; i < data.length; i++) {
        let mode = data[i];
        if (i == index) {
          mode.active = true;
        } else {
          mode.active = false;
        }
        updatedArr.push(mode);
      }
      this.paymentModes = updatedArr;
    },
    closeModal() {
      this.onClose();
    },
    initiatePayment() {
     
      let transactionMode = this.paymentModes[this.selectedIndex].key;
      if (transactionMode === "paypal") {
        return;
      }
      this.loading = true;
      let orderDetail = this.orderDetail;
      orderDetail.transactionMode = transactionMode;
      orderDetail.devicetype = "WEB";
      orderDetail.transactionpurpose = "SUBSCRIPTION";
      if(this.appConfig?.featureEnabled?.optrecurring) {
        orderDetail.optrecurring = this.appConfig?.featureEnabled?.optrecurring;
      }
      if (orderDetail.coupon) {
        orderDetail.couponid = orderDetail.coupon
      }
      eventBus.$emit("initiateRazorpayPayment", orderDetail);
    },
    initiateSdk(data) {
      let sdkScript = document.getElementById("rdp-sdk-script");
      if (sdkScript) {
        this.configureRazorpay(data);
      } else {
        sdkScript = document.createElement("script");
        sdkScript.src = "https://checkout.razorpay.com/v1/checkout.js";
        sdkScript.id = "rzp-sdk-script";
        document.body.appendChild(sdkScript);
        sdkScript.onload = () => {
          this.configureRazorpay(data);
        };
      }
    },

    configureRazorpay(payload) {

      if (payload.errorcode) {
        if (payload.errorcode == '9001') {
          showSnackBar(this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),this.$t('DISMISS'));
        } else {
          showSnackBar(this.$t(payload.reason),this.$t('DISMISS'));
        }
        this.loading = false;
        return;
      }
      let data = payload.referencedata;
      this.referencedata = {...data};
      this.referenceid = payload.referenceid;
      this.loading = false;

      let key = "subscriptionid";
      let keyAlias = "subscription_id";
      let value = data[key];  
      if(value === undefined){
        key = "orderid";
        keyAlias = "order_id";
        value = data[key];
      }
      var options = {
        key: "rzp_live_MLEOrzvuCm5cBY",
        name: this.appConfig.razorpay.name,
        description: this.orderDetail.detail ? this.orderDetail.detail.planname : "Ultra Jhakaas Plan",
        amount: data.amount,
        currency: data.currency,

        config: {
          display: {
            blocks: {
              banks: {
                name: this.paymentModes[this.selectedIndex].title,
                instruments: [
                  {
                    method: this.paymentModes[this.selectedIndex].alias,
                  },
                ],
              },
            },
            sequence: ["block.banks"],
            preferences: {
              show_default_blocks: false,
            },
          },
        },
        handler: function(response) {
          let referencedata = this.referencedata;
          let referenceid = this.referenceid;
          referencedata.payment_id = response.razorpay_payment_id;
          referencedata.subscription_id = response.razorpay_subscription_id;
          referencedata.razorpay_signature = response.razorpay_signature;
         // referencedata[key] = value;
          let callbackPayload = {
            referenceid: referenceid,
            referencedata: referencedata,
          };
          
          this.loading = true;
          eventBus.$emit("razorpay-payment-success-callback", callbackPayload);
        }.bind(this),
      };
      options[keyAlias] = value;
      var rzpSdk = new Razorpay(options);
      rzpSdk.open();
      rzpSdk.on("payment.failed", function(response) {
      //  alert("on error");
      this.loading = false;
      showSnackBar(this.$t("Payment Failed"), this.$t('DISMISS'));
      }.bind(this));

    },
  },

  watch: {
    onOpen(isOpen, oldIsoOpen) {
      var modalObj = document.getElementById("rzp-modal");
      if (isOpen) {
        modalObj.style.display = "flex";
        this.orderDetail = {
          state: true,
          detail: this.plan,
          gateways: [],
          transactionPurpose: "SUBSCRIPTION",
          coupon: this.plan.coupon ? this.plan.coupon : null,
        };
      } else {
        modalObj.style.display = "none";
        this.orderDetail = null;
      }
    },
    plan () {
      let gatewayList = [];
      for (let index in this.paymentModes) {

        if (this.paymentModes[index].planid.includes(this.plan.planid)) {
          gatewayList.push(this.paymentModes[index])
        }
    }
    if (gatewayList.length == 0) {
      this.paymentModes = this.paymentModes.splice(0,4)
    } else {
      this.paymentModes = gatewayList
    }
    
  }
  },
  computed : {
    ...mapGetters(["appConfig"]),
  },
  components: {
    OrderSummary: () => import("@/components/Popups/Payment/OrderSummary.vue"),
    SelectPaymentMethod: () => import("@/components/utils/SelectCard.vue"),
    paypalSub: () => 
      import(/* webpackChunkName: "paypalSub" */ "@/components/gateways/paypalSub.vue"),
    Loading: () => import( /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "./RzpPayment.scss"
</style>
