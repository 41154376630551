<template>
  <div class="feat-container">
    <div class="feature-carousel-container">
      <div class="feature-slider-header">
        <div class="feature-slider-head">
          <div class="feature-slider-head-btns">
            <div :id="`feature-slider-controls`" class="feature-slider-controls">
              <!-- previous-arrow -->
              <button type="button" class="feature-prev" @click="sliderPrevClicked()">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="prev" />
              </button>
              <!-- next-arrow -->
              <button type="button" class="feature-forward" @click="sliderNextClicked()">
                <img src="@/assets/icons/more-than-filled-50.png" width="30px" alt="for" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="featured-carousel" id="featured-carousel">
        <div  class="feat-card" v-for="(item, key) in contents" :key="key">
          <div tabindex="1" class="feat-card-container">
            <div
class="feat-card-box-contain"
:class="[localDisplayLang == 'ara' ? 'feat-card-box-contain-ara' : '']"
:id="`card-${key}`"
:item="item"
:objectid="item.objectid"
:title="titleShow(item)"
@click="playContentWrapper(item, $event)"
:style="[
  { 
    
    width: '100%', /* Full width */
    height: '80vh', /* Height set to 70vh */
    // margin: '10px 0', /* Adds spacing between cards vertically */
    position: 'relative', /* For absolute positioning inside */
    overflow: 'hidden',/* Ensures content stays within bounds */
  }
]"
>
<figure 
  style="
    width: 100%; 
    height: 100%; 
    background-color: #000; 
    position: absolute; 
    top: 0; 
    left: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
  "
>
  <ResponsivePlayer 
    :playerPayload="item" 
    :isPlayTrailer='isPlayTrailer' 
    v-if="acceptPlayer(item, key)" 
    @playBackEndedListner="playBackEndedListner" 
  />
  <img 
    :src="getPoster(item)" 
    :alt="titleShow(item)" 
    style="
      width: 100%; 
      height: 100%; 
      object-fit: cover; /* Ensures image covers the entire area */
    " 
    v-else
  >
</figure>

              <div
                :class="[localDisplayLang === 'ara' ? 'content-tag-right' : 'content-tag']"
                v-if="isObjectTag(item)"
              >
                <div v-if="pickTagForContent(item)" class="content-tag-item">
                  <img src="@/assets/icons/Premium_1_scg.svg" alt="crown">
                  <!-- <p>{{ pickTagForContent(item) }}</p> -->
                </div>
              </div>

              <div class="card-text" id="card-text" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" v-if="!item.deeplink && !allowAutoPlay">
                <div class="card-title">
                  <p style="font-size: 1.1rem;font-weight: 800;">{{ item.title.toUpperCase() }}</p>
                </div>

                <div class="tags"
               
                >
                
                  <!-- <p>{{ $t(item.tags && item.tags.toString().toLowerCase().includes('ct-music') ? 'Music' : item.category == 'TVSHOW' ? 'SERIES' : item.category) }}</p> -->
                  <p>{{ $t( getUpdatedTag(item.tags)) || $t(item.category == 'TVSHOW' ? 'SERIES' : item.category)   }}</p>
                 <div
                 >
                  <p 
                  >{{ $t(item.genre) }}</p>
                 </div>
                  <!-- <p v-if="item.hasOwnProperty('contentlanguage') && item.contentlanguage[0]">
                    {{ $t(item.contentlanguage[0].toLowerCase()) }}
                  </p> -->
                  <p>{{ item.productionyear }}</p>
                  <p dir="ltr" class="pg-rating" v-if="item.pgrating">
                    {{ item.pgrating }}
                  </p>
                </div>
                <div v-if="(item.category === 'MOVIE' || (item.tags && item.tags.toString() && (item.tags.toString().toLowerCase().includes('ct-natak') || item.tags.toString().toLowerCase().includes('ct-music')))) && !shouldShowDuration(item)">
                  <div class="duration-views-eng" v-if="localDisplayLang !== 'ara'" dir="ltr">
                    <img src="@/assets/icons/Duration.svg" alt="items-count" />
                    <p class="duration-views-eng-number w-100">
                      {{ calcTimeFormat(item.duration) }}
                    </p>
                  </div>
                  <div class="duration-views-ara" dir="ltr" v-else>
                    <img src="@/assets/icons/Duration.svg" alt="items-count" />
                    <p class="duration-views-eng-number w-100">
                      {{ calcTimeFormat(item.duration) }}
                    </p>
                  </div>
                  <!-- <p>{{ item.views }}</p> -->
                </div>
                <div class="duration-views" v-if="item.category === 'TVSHOW' && !(getUpdatedTag(item.tags))">
                  <img src="@/assets/icons/season-light.svg" alt="items-count" />
                  <p class="duration-views-number">
                    {{ item.seasoncount }}
                    {{ item.seasoncount === 1 ? $t("Season") : $t("Seasons") }}
                  </p>
                  <!-- <p>{{ item.views }}</p> -->
                </div>
                <!-- <div class="card-description" v-if="item.shortdescription">
                  <p v-if="item.shortdescription.length <= 100">
                    {{ item.shortdescription }}
                  </p>
                  <p v-else>
                    {{ item.shortdescription.substring(0, 100) }}...
                  </p>
                </div> -->

                <div class="content-action-btns" v-if="item.hasOwnProperty('objectid')">
                  <div class="activity-buttons">
                    <LikeBtn :content="item" :width="'25px'" :height="'25px'" class="hover-change"></LikeBtn>
                    <div class="triangle" :class="triangleColor"></div>
                    <div class="tooltip" :class="toolTipColor">
                      <span>{{ $t("like") }}</span>
                    </div>
                  </div>
                  <!-- <div class="activity-buttons">
                    <DislikeBtn :content="item" :width="'25px'" :height="'25px'" class="hover-change"></DislikeBtn>
                    <div class="triangle" :class="triangleColor"></div>
                    <div class="tooltip" :class="toolTipColor">
                      <span>{{ $t("dislike") }}</span>
                    </div>
                  </div> -->
                  <div class="activity-buttons">
                    <detailIcon :content="item" :width="'25px'" :height="'25px'" class="hover-change"></detailIcon>
                    <div class="triangle" :class="triangleColor"></div>
                    <div class="tooltip" :class="toolTipColor">
                      <span>{{ $t("share") }}</span>
                    </div>
                  </div>
                  <div class="activity-buttons">
                    <WatchListBtn
                      :content="item"
                      :width="'25px'"
                      :height="'25px'"
                      class="hover-change"
                    ></WatchListBtn>
                    <div class="triangle" :class="triangleColor"></div>
                    <div class="tooltip" :class="toolTipColor">
                      <span>{{ $t("My List") }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="play-btn-container"> -->
                <!-- <img
                  class="play-btn"
                  src="@/assets/icons/Ic_Play1.svg"
                  alt
                  @click="playContent(item)"
                />-->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="feat-nav-container" id="feat-nav-container">
        <button
          class="nav-button"
          type="button"
          v-for="(item, key) in contents"
          :key="key"
          @click="navBtnClicked(key)"
        ></button>
      </div>
    </div>
    <AgeRestrictionModal
      @onActionComplete="onAgeModalActionAccept"
      v-if="showAgePopUp" 
    />
    <PinModal
      id="pc-pin-detail-modal-player-config"
      :open="showPinModal"
      @onClose="closePinModal"
      :mode="pinMode"
      @onActionComplete="onPinModalActionComplete"
      :pinError="pinModalError"
      :callbackParentalLockFlag="callbackParentalLockFlag"
      :loading="pinModalLoading"
      @onForgotPin="openForgotPinModal"

    />
    <ForgotPinModal
      id="pc-forgot-pin-modal"
      :open="showForgotPinModal"
      @onClose="closeForgotPinModal"
      @onActionComplete="onForgotPinModalActionComplete"
      :pinError="forgotPinModalError"
      @onResend="resendOtp"
      :loading="loading"
    />
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _projectName } from "@/provider-config.js";
export default {
name: "FeaturedCarousel",
props: {
  featuredScreen: {
    type: Object,
  },
  contents: {
    type: Array,
  },
},
data() {
  return {
    // enableFeatured: false,
    cardHeight: 430,
    slider: null,
    triangleColor: null,
    toolTipColor: null,
    localDisplayLang: null,
    counter: 1,
    activeIndex: 0,
    isPlayTrailer: true,
    showAgePopUp: false,
    allowAutoPlay: false,      
    showPinModal: false,
    showForgotPinModal: false,
    pinModalLoading: false,
    parentalControlsEnabled: false,
    callbackParentalLockFlag: "",      
    pinModalError: "error message",
    pinMode: "restrict",
    forgotPinModalError: "",
    forgetpinloader: false,
    loading: false,
    autoPlayInFeatureCaurosel: false
  };
},
computed: {
  ...mapGetters([
    "getToken",
    "subscriberid",
    "availabilityList",
    "subscriptionList",
    "purchaseList",
    "getRtl",
    "getDisplayLanguageCode",
    "appConfig",
  ]),
},
created() {
  this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mar' : this.localDisplayLang;
  let isEnabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;
  this.parentalControlsEnabled = isEnabled;
  this.actChangeBackgroundCarousel();

  //checking for change of language in local storage value
  eventBus.$on("check-local-storage", (data) => {
    this.localDisplayLang = data;
    this.actChangeBackgroundCarousel();
  });
  // tooltip color
  this.toolTipColor =
    _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
      ? "tooltip-color"
      : _projectName === "NAMMAFLIX"
      ? "tooltip-color-white"
      : "";

  // tooltip triangle Color change
  this.triangleColor =
    _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
      ? "triangle-color"
      : _projectName === "NAMMAFLIX"
      ? "triangle-color-white"
      : "";
},
mounted() {
  this.autoPlayInFeatureCaurosel = this.appConfig?.featureEnabled?.autoPlayInFeatureCaurosel;
  // this.autoPlayInFeatureCaurosel = true;
  this.renderCarousel();
},
methods: {
  ...mapActions(["getScreenContents", "parentalControlVerifyOldPin", "parentalControlPinSet", "parentalControlResetPin"]),
  getUpdatedTag(tags) {
    if (!tags) return "";
    let configCTTag = "";
    for (let tag of tags) {
      if (tag.includes('CT-')) {
          configCTTag = tag.split('CT-')[1];
        if (this.appConfig.contentTypeTags) {
          let language = 'en'
          for (let lang of window.PROVIDER_SETUP_CONFIG.supportLanguageList) {
            if (lang.includes(localStorage.getItem('setDisplayLanguageCode'))) {
              language = lang;
            }
             
          }
          configCTTag = this.appConfig.contentTypeTags[language][configCTTag]  || configCTTag;
          break;
        }
      }
    }
    return configCTTag;
  },
  shouldShowDuration(item) {
  return item.duration == 30;
 },
  actChangeBackgroundCarousel() {
    let deg = this.localDisplayLang == "eng" ? "90deg" : "-90deg";
    document.documentElement.style.setProperty("--backgroud-carousel-color-rotation", deg);
  },
  isObjectTag(content) {
    if (content.hasOwnProperty("objecttag")) {
      if (Array.isArray(content.objecttag)) {
        return content.objecttag.length === 0 ? false : true;
      } else {
        return content.objecttag === null ? false : true;
      }
    }
  },
  pickTagForContent(content) {
    if (content.hasOwnProperty("objecttag")) {
      let tag = content.objecttag[0];
      let contenttags = this.appConfig.contentTags;
      if (this.localDisplayLang === "mar") {
        return contenttags.mr[`${tag}`];
      } else {
        return contenttags.en[`${tag}`];
      }
    }
  },
  playContentWrapper(item, e) {
    if(this.autoPlayInFeatureCaurosel && this.allowAutoPlay) return;
    localStorage.setItem("playback_source", "Featured");
    if(item.deeplink) {
      let redirectionData = item.deeplink.includes('https://ultrajhakaas.com') && this.localDisplayLang == 'mar' ? item.deeplink.replace("https://ultrajhakaas.com", "https://ultrajhakaas.com/mr") : item.deeplink;
      window.open(redirectionData);
      return;
    }
    this.playContent(item, e);
  },
  carouseldata(e) {
    let objectid =
      e.srcElement && e.srcElement.attributes.objectid && e.srcElement.attributes.objectid.value
        ? e.srcElement.attributes.objectid.value
        : "";

    let item = this.contents.filter((data) => {
      if (data.objectid == objectid) return data;
    });
    this.playContent(item[0], null);
  },
  //fetch featured carousel card height.
  fetchCardHeight() {
    let bodyWidth = document.body.offsetWidth - 600;
    let cardHeight = Math.round(bodyWidth / (16 / 9));
    if (cardHeight) {
      this.cardHeight = cardHeight + 80;
      if (this.isTablet()) {
        this.cardHeight += 50;
      }
    }
  },

  //fetch poster for featured content.
  getPoster(content) {
    // console.log("content", content);
    if (Array.isArray(content.poster)) {
      if (content.poster && content.poster.length) {
        let index = content.poster.findIndex((element) => {
          return (element.quality === "HD" || element.quality === "SD") && element.postertype === "LANDSCAPE";
        });
        // console.log("getPoster", index);
        if (index > -1) {
          return screen.width > 768
            ? content.poster[index].filelist[1].filename
            : content.poster[index].filelist[1].filename;
        }
      } else {
        return "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/ll2ufl37xpr_LANDSCAPE_LOW.jpg";
      }
    } else {
      if (content.poster) {
        return this.localDisplayLang === "ara" ? content.poster.LANDSCAPE.ar : content.poster.LANDSCAPE.en;
      }
    }
  },

  //play item from featured carousel.
  playContent(item, e) {
    if (e && (e.target.nodeName == "svg" || e.target.nodeName == "path")) {
      console.log("play content aborted : ", item);
      return;
    }
    if (item.hasOwnProperty("deeplink") && item.deeplink) {
      let id = item.deeplink.split("/")[3];
      if (id == "plan") {

          let planid = item.deeplink.split("/")[4];
          let coupon= "";

          if(item.deeplink.split("/")[5]) {
            coupon = item.deeplink.split("/")[5]
          }

          const routeParams = {
            planId: planid,
          }

          if(coupon) {
            routeParams.coupon = coupon;
          }

          this.$router.push({
            name: "planandcoupondeeplink",
            params: routeParams,
          });
        // }
      } else {
        this.$router.push({ name: "screen", params: { section: `${id}` } });
      }
    } else if (item.hasOwnProperty("objectid")) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (screen.width > 0) {
        if (item.hasOwnProperty("objectid")) {
          if(item?.tags?.toString()?.toLowerCase()?.includes('ct-music')) {
            let title = this.spacialCharEncoding(item.defaulttitle || item.title);
            this.$router.push({ name: "detailPageMusic", params: { contentId: item.objectid.toLowerCase(), mediaTitle: title, lang: currentLanguage } });
          } else if (item.category == "TVSHOW") {
       
             let title = this.spacialCharEncoding(item.defaulttitle);

            this.$router.push({
              name: "detailPage",
              params: { contentId: item.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage },
              children: [
              {name: "episodes",
              params: { id: 1 }}
              ]
            });
          } else {
             let title = this.spacialCharEncoding(item.defaulttitle);
            this.$router.push({
              name: "detailPageMovie",
              params: { mediaTitle: title.toLowerCase(), contentId: item.objectid.toLowerCase(), lang: currentLanguage },
            });
          }
        }
      } else {
        //this.$router.push({ name: "detail", params: { contentid: item.objectid } });
      }
    }
  },

  //construct carousel for featured contents.
  renderCarousel() {
    if (document.getElementById("featured-carousel")) {
      this.$nextTick(() => {
        if (this.slider) return;
        if (!document.getElementById("featured-carousel")) return;

        let tanyPros = {
          startIndex: 0,
          autoplayDirection: "forward",
        };

        if (this.localDisplayLang == "ara") {
          tanyPros = {
            startIndex: this.contents.length - 1,
            autoplayDirection: "backward",
          };
        }
        const tnsPayload = {
          container: "#featured-carousel",
          navContainer: "#feat-nav-container",
          controlsContainer: "#feature-slider-controls",
          slideBy: 1,
          autoplay: true,
          autoplayHoverPause: true,
          autoplayButtonOutput: false,
          autoHeight: true,
          freezable: false,
          loop: false,
          rewind: false,
          // controls: false,
          nav: true,
          speed: 400,
          items: 1,
          startIndex: tanyPros.startIndex,
          autoplayDirection: tanyPros.autoplayDirection,
          gutter: 5,
          responsive: {
            // 1350: {
            //   items: 1,
            //   gutter: 50,
            //   edgePadding: 300
            // },
            1200: {
              items: 1,
              gutter: 10,
              edgePadding: 320,
            },
            1024: {
              items: 1,
              gutter: 10,
              edgePadding: 150,
            },
            768: {
              items: 1,
              gutter: 10,
              edgePadding: 25,
            },
            576: {
              items: 1,
              gutter: 10,
              edgePadding: 25,
            },
          },
        };
        
        if (this.contents.length >= 2) {
          tnsPayload.loop = true;
        }
        this.slider = tns(tnsPayload);
        // Get slider information
        const sliderInfo = this.slider.getInfo();

        // Get active slide index
        this.activeIndex = sliderInfo.index;
        console.log(`Active slide index: ${this.activeIndex}`);

        // Get total number of slides
        const totalSlides = sliderInfo.slideCount;
        console.log(`Total number of slides: ${totalSlides}`);
        // ...and so on
        let contentsData = this.contents[this.activeIndex - 2];
        if(contentsData && contentsData.trailer && this.autoPlayInFeatureCaurosel) {
          this.handleAutoPlay(false);
        }
        // Listen to slide change event
        this.slider.events.on('indexChanged', () => {
          const updatedSliderInfo = this.slider.getInfo();
          this.activeIndex = updatedSliderInfo.index;
          this.allowAutoPlay = false;          
          let updatedContents = this.contents[this.activeIndex - 2];
          if(updatedContents && updatedContents.trailer && this.autoPlayInFeatureCaurosel) {
            this.handleAutoPlay(false);
          }
        });
      
        // const info = this.slider.getInfo();
        // console.log("infor", info)
        // this.slider.goTo('last');
      });
    }
  },
  //button navigator for featured carousel.
  navBtnClicked(key) {
    if (this.slider) {
      this.slider.play();
      const activeIndex = this.slider.getInfo().index;
    }
  },
  sliderPrevClicked() {
    const info = this.slider.getInfo();
    this.slider.goTo(info.displayIndex - 1);
    const activeIndex = this.slider.getInfo().index;
    console.log(`sliderPrevClicked Active slide index: ${activeIndex}`);
    setTimeout(() => {
      this.slider.play();
    }, 100);
  },
  sliderNextClicked() {
    const info = this.slider.getInfo();
    this.slider.goTo(info.displayIndex - 1);
    const activeIndex = this.slider.getInfo();
    console.log(`sliderPrevClicked Active slide index: ${activeIndex}`);
    setTimeout(() => {
      this.slider.play();
    }, 100);
  },

  handleAutoPlay(status = false) {
    // this.showPinModal = false;
    let pgrating = this.contents[this.activeIndex - 2].pgrating;
    if(!this.subscriberid && !status) {
      if(!this.isContentPlayableForAnonymoususer()) {
        // eventBus.$emit("open-age-restriction-modal", true);
        this.showAgePopUp = true;
        return;
      }
    }
    if (
      !this.isContentPlayableParentalLock(
        this.getCurrentProfile(),
        pgrating
      ) &&
      !status 
    ) {
      if(this.parentalControlsEnabled){
        this.callbackParentalLockFlag = "TRAILER-AUTO-PLAY";
        this.showPinModal = true;
        return;
      }     
    }
    setTimeout(() => {this.allowAutoPlay = true;}, 1000)
    
  },

  onAgeModalActionAccept(accept = false) {
    if(accept) {
      this.showAgePopUp = false;
      this.handleAutoPlay(true);
    } else {
      this.showAgePopUp = false;
    }
  },

  closePinModal() {
    this.showPinModal = false;
    this.callbackParentalLockFlag = "";
  },

  onPinModalActionComplete(enteredPin) {
    this.isLoaderEnabled = true;
    this.pinModalLoading = true;

    this.confirmDetailParentPin = enteredPin;
    let payload = {
      pin: this.confirmDetailParentPin,
    };
    this.parentalControlVerifyOldPin(payload)
      .then((data) => {
        this.pinModalLoading = false;
        if (data.data.reason) {
          this.pinModalError = this.$t(data.data.reason);
          return;
        } else if (data.data.success) {
          //this.showPinModal = false;
          this.isLoaderEnabled = false;
          if(this.callbackParentalLockFlag == "TRAILER-AUTO-PLAY") {
            this.handleAutoPlay(true);
          }

          this.closePinModal();
        }
      })
      .catch((error) => {
        console.log("parentalControlVerifyOldPin error -> ", error);
      });
  },

  onForgotPinModalActionComplete(data) {
    // forget pin reset api call
    this.loading = true;
    let forgetPinPayload = {
      pin: data.pin,
      OTP: data.otp,
    };
    this.parentalControlResetPin(forgetPinPayload)
      .then((data) => {
        this.loading = false;
        if (data.data.reason) {
          this.forgotPinModalError = this.$t(data.data.reason);
          return;
        } else if (data.data.success) {
          this.showForgotPinModal = false;
        }
        console.log("Resret Pin Response", data);
      })
      .catch((error) => {
        console.log("parentalControlResetPin error -> ", error);
      });
  },

  openForgotPinModal() {
    this.showForgotPinModal = true;
    this.loading = true;
    //call api to get otp
    let payload = {
      otp_type: "parentalControlPinUpdate",
    };
    //do something here. (Make call to the API)
    //received otp to email
    this.parentalControlPinSet(payload)
      .then((data) => {
        this.loading = false;
        if (data.data.reason) {
          this.pinModalError = this.$t(data.data.reason);
          return;
        } else if (data.data.success) {
          console.log("parentalControlPinSet", data);
        }
      })
      .catch((error) => {
        console.log("parentalControlPinSet error -> ", error);
      });
  },

  isContentPlayableForAnonymoususer() {
    let ageRatingList = this.appConfig.ageRatingList; 
    let ageLimit = this.appConfig.ageLimit;
    let contentsData = this.contents[this.activeIndex - 2];
    let ContentPgrating = contentsData.pgrating;
    for (let rating in ageRatingList) {
      if (rating == ageLimit) {
        return ageRatingList[rating].includes(ContentPgrating);      
      }
    }
  },
  resendOtp() {
    //do something here. (Make call to the API)
    this.loading = true;
    let payload = {
      otp_type: "parentalControlPinUpdate",
    };
    //do something here. (Make call to the API)
    //received otp to email
    this.parentalControlPinSet(payload)
      .then((data) => {
        this.loading = false;
        if (data.data.reason) {
          this.pinModalError = this.$t(data.data.reason);
          return;
        } else if (data.data.success) {
          console.log("parentalControlPinSet", data);
        }
      })
      .catch((error) => {
        console.log("parentalControlPinSet error -> ", error);
      });
  },
  closeForgotPinModal() {
    this.showForgotPinModal = false;
  },

  playBackEndedListner(action = false) {
  if(action) {
    this.allowAutoPlay = false;
  }
  },

  titleShow(item) {
    return (!item.deeplink && !this.allowAutoPlay) ? item.title.toUpperCase() : '';
  },

  acceptPlayer(item, key) {
    return this.autoPlayInFeatureCaurosel && this.activeIndex === (key + 2) && item?.trailer && this.allowAutoPlay;
  }
},
components: {
  LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
  WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
  detailIcon: () => import(/* webpackChunkName: "detailicon" */ "@/components/SvgImages/detailIcon.vue"),
  ResponsivePlayer: () => import(/* webpackChunkName: "responsivePlayer" */ "@/components/MiniPlayer/ResponsivePlayer.vue"),
  AgeRestrictionModal: () => import(/* webpackChunkName: "ageRestrictionModal" */ "@/components/Popups/Restriction/AgeRestriction.vue"),
  ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),    
  PinModal: () => import(/* webpackChunkName: "PinModal" */  "@/components/utils/PinModal.vue"),
 },
mixins: [Utility],
};
</script>
<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";
@import"./FeaturedCarousel.scss";
</style>
