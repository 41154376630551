<template>
  <div class="about" slot="component">
    <div class="about-images">
      <!-- <img
          v-if="providerUniqueId === 'net54321'"
          class="about-images-net5-logo"
          src="@/assets/icons/Net5.svg"
          alt="net5_logo"
        />
        <img
          v-else-if="providerUniqueId === 'z01x1wPo'"
          class="about-images-vlive-logo"
          src="@/assets/icons/nammaflix_logo.png"
          alt
        />-->
      <img
        class="about-images-noorPlay_logo"
        src="@/assets/icons/ic_splash_logo.png"
        alt
      />
      <!-- <img v-else class="about-images-vlive-logo" src="@/assets/icons/log.svg" alt /> -->
      <!-- <img class="about-images-vlive-title" src="@/assets/icons/text.svg" alt /> -->
      <!-- <p class="about-images-version">V1.0.1</p> -->
    </div>
    <div class="about-description">
      <p class="about-description-paragraph">
        {{
          localDisplayLang === "mr"
            ? appConfig.providerDetails.aboutText.mr
            : appConfig.providerDetails.aboutText.default
        }}
      </p>
    </div>
    <div class="about-social">
      <p class="about-social-follow">{{ $t("follow us") }}</p>
      <div class="about-social-icons">
        <a
          :href="appConfig.providerDetails.social.facebook"
          target=" _blank"
          rel="”noreferrer”"
          aria-label="facebook link"
        >
          <img src="@/assets/icons/newfb.svg" alt="facebook_img" />
        </a>
        <a
          :href="appConfig.providerDetails.social.instagram"
          target="_blank"
          rel="”noreferrer”"
          aria-label="instagram link"
        >
          <img src="@/assets/icons/newinsta.svg" alt="instagram_img" />
        </a>
        <a
          :href="appConfig.providerDetails.social.twitter"
          target="_blank"
          rel="”noreferrer”"
          aria-label="twitter link"
        >
          <img src="@/assets/icons/Socialuj.svg" alt="twitter_img" 
          />
        </a>
        <!-- <a
            :href="appConfig.providerDetails.social.pinterest"
            target="_blank"
            rel=”noreferrer”
            aria-label="pinterest link"
          >
            <img src="@/assets/icons/newpinterest.svg" alt="pinterest_img" />
          </a>-->
        <a
          :href="appConfig.providerDetails.social.youtube"
          target="_blank"
          rel="”noreferrer”"
          aria-label="pinterest link"
        >
          <img src="@/assets/icons/youtube.svg" alt="youtube" />
        </a>
      </div>
    </div>
    <div class="about-app-share">
      <button
        class="button-primary"
        :class="[
          providerUniqueId === 'z01x1wPo'
            ? 'primary-nammaflix-button'
            : 'primary-net5-button',
        ]"
        @click="shareApp"
      >
        {{ $t("share the app") }}
      </button>
    </div>
    <p class="version-project">v2.0.2</p>
    <div class="about-policy-links">
      <ul style="display: flex">
        <li>
          <router-link tabindex="1" class="text" :to="showTermsOfUse()">
            {{ $t("terms of use") }}
          </router-link>
        </li>
        <li style="text-align: center; color: white">
          {{ $t("And") }}
        </li>
        <li>
          <router-link tabindex="1" class="text" :to="showPrivacyPolicy()">
            {{ $t("privacy policy") }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    closePopup: {
      type: Function,
    },
  },
  data() {
    return {
      width: "50%",
      margin: "",
      radiusType: "full",
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  mounted() {
    var allMetaElements = document.getElementsByTagName("meta");
    document.title = "About Us: Company Profile - Ultra Jhakaas";
    for (var metaValue = 0; metaValue < allMetaElements.length; metaValue++) {
      if (allMetaElements[metaValue].getAttribute("name") == "description") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "Ultra Jhakaas is your one-stop destination for non-stop authentic 100% Marathi entertainment."
        );
      }

      if (
        allMetaElements[metaValue].getAttribute("property") == "og:description"
      ) {
        allMetaElements[metaValue].setAttribute(
          "content",
          "Ultra Jhakaas is your one-stop destination for non-stop authentic 100% Marathi entertainment."
        );
      }

      if (
        allMetaElements[metaValue].getAttribute("name") == "twitter:description"
      ) {
        allMetaElements[metaValue].setAttribute(
          "content",
          "Ultra Jhakaas is your one-stop destination for non-stop authentic 100% Marathi entertainment."
        );
      }

      if (allMetaElements[metaValue].getAttribute("name") == "keyword") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "Ultra Jhakaas about us,"
        );
      }

      if (allMetaElements[metaValue].getAttribute("property") == "og:title") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "About Us: Company Profile - Ultra Jhakaas"
        );
      }

      if (allMetaElements[metaValue].getAttribute("name") == "twitter:title") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "About Us: Company Profile - Ultra Jhakaas"
        );
      }
    }
  },

  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    window.scrollTo(0, 0);

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //setting margin for userauth popup based on screen width.
    if (screen.width < 576) {
      this.margin = "45% auto";
    } else {
      this.margin = "10% auto";
    }
  },
  methods: {
    closeAbout() {
      this.closePopup();
    },
    shareApp() {
      eventBus.$emit("show-share-app-popup", true);
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if (!window.PROVIDER_SETUP_CONFIG.isTermsEnabled) {
        return;
      }

      return { name: "termsofuse", params: { lang: currentLanguage } };
    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if (!window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled) {
        return;
      }

      return { name: "privacypolicy", params: { lang: currentLanguage } };
    },
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./About.scss";
</style>
